<template>
  <transition name="modal-window">
    <div
      ref="modalContainer"
      tabindex="0"
      class="modal-window-container"
      @keydown.esc="closeModal('esc')"
      @click.self="closeModal('overlay')"
    >
      <!-- @click.self="closeModal('overlay')" ^^ for above, test double clicks -->
      <div class="modal-content">
        <CustomButton
          class="modal-window-close"
          style-name="tertiary"
          aria-label="Close Modal"
          :icon-only="true"
          @click-button="closeModal('close')"
        >
          <CloseLine />
        </CustomButton>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { lockBody, unlockBody } from '~/util/eventHandler';
import { useCartStore } from '~/store/cart';

const cartStore = useCartStore();
const emit = defineEmits(['closed']);
defineExpose({
  closeModal,
});
const modalContainer = ref<HTMLElement | null>(null);

const props = defineProps({
  // Used for ethcing modal, where multiple modals are open on top of one-another
  // we dont want to close the main modal when a secondary one is opened
  unlockBodyOnClose: {
    type: Boolean,
    default: true,
  },
  lockBodyOnOpen: {
    type: Boolean,
    default: true,
  },
});
// This mounted hook requires that component is protected by v-if for showing, or else the body will lock on page load
onMounted(async () => {
  // TODO - check this after removing v-if on cart modal
  await nextTick();
  if (props.lockBodyOnOpen) {
    lockBody();
  }
  modalContainer.value?.focus();
});

function closeModal(type: string): void {
  if (props.unlockBodyOnClose) {
    unlockBody();
  }
  cartStore.setShowCartModal(false);
  emit('closed', type);
}
</script>

<style scoped lang="scss">
.modal-window-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: map.get(local-vars.$zindex, 'modal-overlay');
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100%;
  &.full-window {
    &.cart-modal {
      opacity: 0;
      z-index: -10;
      // transition: all 0.3s ease;
      .modal-content {
        position: fixed;
        top: 0;
        right: -100%;
        transition: right 0.3s ease;
        display: flex;
        border-radius: 4px;
        min-height: auto;
      }
      &:not(.open) {
        transition: all 0.3s ease;
      }
      &.open {
        opacity: 1;
        z-index: map.get(local-vars.$zindex, 'modal-overlay');
        .modal-content {
          right: 0;
        }
      }
      .modal-window-close {
        top: 12px;
      }
      @include local-mixins.desktop {
        justify-content: flex-end;
        .modal-content {
          border-radius: 0;
          width: 480px;
          min-height: 100vh;
          overflow: hidden;
          // right: 0;
        }
      }
    }
    .modal-content {
      max-height: 100vh;
      min-height: fit-content;
      max-width: 100vw;
      width: 100%;
      height: 100%;
    }
  }
}
.modal-content {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.modal-window-close {
  background-color: $color-neutral-cool-50;
  border-radius: 50%;
  padding: 6px;
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: map.get(local-vars.$zindex, 'modal-window');
  display: inline-flex;
  width: auto;
}
</style>

<style lang="scss">
.modal-window-container {
  background-color: rgba(0, 0, 0, 0.75);
}
.modal-content {
  background-color: $color-neutral-white;
  width: 95vw;
  min-height: 50vh;
  max-height: 90vh;
  @include local-mixins.desktop {
    width: 100%;
    max-width: map.get(local-vars.$breakpoints, 'large');
  }
}
.modal-window-enter-active,
.modal-window-leave-active {
  transition: opacity 0.5s;
}
.modal-window-enter,
.modal-window-leave-to {
  opacity: 0;
}
.modal-window-close.btn :deep(svg) {
  margin-right: 0;
}
.modal-window-close {
  path {
    fill: $color-neutral-cool-900;
    &:hover,
    &:focus {
      fill: $color-primary-600;
    }
  }
}
</style>
